<template>
  <div class="columns">
    <Kategori />
    <div class="section column borderKonten">
      <div v-if="items.length != 0">
          <div id="objectCards" class="columns is-multiline">
            <div class="column is-4-tablet is-3-desktop" v-for="(item, i) in items" :key="i.idObjek">
              <div class="container">
                  <div id="cardContent" class="bg">
                      <img id="image" src="@/assets/bgCard.png">
                      <h2 v-if="idLabel == 76" style="font-size: larger; font-size: 2rem; font-weight: bold; position: absolute; top: 4rem; color: white;">{{item.idObjek}}</h2>
                      <h2 v-else class="judul">{{item.idObjek}}</h2>
                      <div class="overlay">
                          <div class="bot">
                          <router-link id="seeMore" :to="{ name: 'Objek', params: { id: i, objek: item, idLabel: idLabel} }"><span >See More</span></router-link>
                          </div>
                      </div>
                  </div>
              </div>
            </div>
          </div>
      </div>
      <div v-if="items2.length != 0">
          <div id="objectCards" class="columns is-multiline">
            <div class="column is-4-tablet is-3-desktop" v-for="(item, i) in items2" :key="i.idObjek">
              <div class="container">
                  <div id="cardContent" class="bg">
                      <img id="image" src="@/assets/bgCard.png">
                      <h2 class="judul">{{item.namaObjek}}</h2>
                      <div class="overlay">
                          <div class="bot">
                          <router-link id="seeMore" :to="{ name: 'Objek', params: { id: item.idObjek, objek: [], idLabel: idLabel} }"><span >See More</span></router-link>
                          </div>
                      </div>
                  </div>
              </div>
            </div>
          </div>
          <p id="judulDetail" class="title"><br> Artikel</p>
          <div>
              <div>
                <Artikel />
              </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import Kategori from './Kategori.vue'
import axios from "axios"
import Artikel from './Artikel.vue'
export default {
    name: "FilosofiBudaya",
    components : {
        Kategori,
        Artikel
    },
    data(){
        return{
          infos:[],
          items:[],
          items2: [],
          idLabel: null,
          namaLabel: null
        };
    },
    created() {
        // this.getKategoriJoinedSub();
        this.getObjekById();
    },
    methods: {
        async getObjekById() {
          var params = this.$route.params.id.split("-")
          this.idLabel = String(params[0])
          this.namaLabel = params[1]
          try {
              var response2 = await axios.get(`https://portalapi.alunalun.info/kategori/subkategori/objek/${this.idLabel}`);
              this.items2 = response2.data;
              var response = await axios.get(`https://portalapi.alunalun.info/${this.namaLabel}`);
              this.items = response.data;
              for(let i=0; i<this.items.length; i++){
                this.items[i].idObjek = this.items[i].idObjek.replaceAll("\"", "")
              }
          // eslint-disable-next-line no-empty
          } catch (err) {}
        },
        // async getKategoriJoinedSub() {
        //   try {
        //       const response = await axios.get(`https://portalapi.alunalun.info/kategorisub/${this.$route.params.id}`);
        //       this.infos = response.data;
        //   } catch (err) {
        //       console.log(err);
        //   }
        // }
    },
}
</script>