<template>
    <vsa-list class="vsaList">
    <!-- Here you can use v-for to loop through items  -->
        <vsa-item class="vsaItem" v-for="(article, i) in articles" :key="i.doc_id">
            <vsa-heading>
            {{article.headline}}
            </vsa-heading>

            <vsa-content>
            {{article.doctext}}
            <p style="margin-top: 1rem;">Source : <a :href="article.sources">{{article.sources}}</a></p>
            </vsa-content>
        </vsa-item>
    </vsa-list>
</template>

<script>
import{
    VsaList,
    VsaItem,
    VsaHeading,
    VsaContent,
} from 'vue-simple-accordion';
import 'vue-simple-accordion/dist/vue-simple-accordion.css';
import axios from "axios";

export default {
    name: 'Artikel',
    components: {
        VsaList,
        VsaItem,
        VsaHeading,
        VsaContent,
    },
    data(){
        return{
            articles:[],
        }
    },
    created(){
        this.getArticles();
    },
    methods: {
        async getArticles() {
            try {
                const response = await axios.get(`https://portalapi.alunalun.info/articles/${this.$route.params.id}`);
                this.articles = response.data;
            } catch (err) {
                console.log(err);
            }
        },
    }
}

</script>

<style scoped>
.vsa-list{
    --vsa-max-width: 100%;
    --vsa-min-width: 300px;
    --vsa-text-color: rgba(55, 55, 55, 1);
    --vsa-highlight-color: #A4161A;
    --vsa-bg-color: rgba(255, 255, 255, 1);
    --vsa-border-color: rgba(0, 0, 0, 0.2);
    --vsa-border-width: 1px;
    --vsa-border-style: solid;
    --vsa-heading-padding: 1rem 1rem;
    --vsa-content-padding: 1rem 1rem;
    --vsa-default-icon-size: 1;
    text-align: justify;
}
</style>